<template>
  <div class="fields">
    <ValidationObserver v-slot="{ handleSubmit }"
                        ref="observer"
    >
      <form id="contact_form"
            @submit.prevent="handleSubmit(onSubmit)"
      >
        <div class="g-recaptcha"
             data-sitekey="6LdaiSMcAAAAAI-s1gYWeF5adHxyEPPtAoazp47s"
        ></div>
        <div class="first">
          <ul>
            <ValidationProvider
                name="name"
                rules="required"
                v-slot="{ errors }"
            >
              <li>
                <input
                    v-model="formData.name"
                    type="text"
                    name="name"
                    placeholder="Naam"
                    autocomplete="off"
                />
                <span class="input-error">{{ errors[0] ? 'Een naam is verplicht' : '' }}</span>
              </li>
            </ValidationProvider>

            <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
            >
              <li>
                <input
                    type="text"
                    name="email"
                    rules="required|email"
                    v-model="formData.email"
                    placeholder="Email"
                />
                <span class="input-error">{{ errors[0] ? 'Vul a.u.b. een geldig email in' : '' }}</span>
              </li>
            </ValidationProvider>
            <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
            >
              <li>
                <textarea
                    name="message"
                    v-model="formData.message"
                    placeholder="Bericht"
                ></textarea>
                <span class="input-error">{{ errors[0] ? 'Bericht is verplicht' : '' }}</span>
              </li>
            </ValidationProvider>
          </ul>
        </div>

        <div class="tokyo_tm_button"
             style="margin-bottom: 15px; color: white;"
        >
          <button type="submit"
                  class="ib-button"
          >
            Versturen
          </button>
        </div>

        <span v-show="success">Bericht is verzonden</span>

      </form>
    </ValidationObserver>


  </div>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {init, send}         from 'emailjs-com';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      success : false,
      formData: {
        name   : "",
        email  : "",
        message: "",
      },
    };
  },
  methods: {
    onSubmit() {
      send('service_muogock', 'template_o6wa38p', {
        to_name  : "Jeremy Stam",
        from_name: this.formData.name,
        message  : this.formData.message,
        reply_to : this.formData.email,
      }, 'user_cdb4bXqJWqku704ktgdvK').then((result) => {
        console.log('SUCCESS!', result.status, result.text);
        this.success = true;
        window.requestAnimationFrame(() => {
          this.$refs.observer.reset();
          this.formData.name = ''
          this.formData.message = ''
          this.formData.email = ''
        });
      }, (error) => {
        console.log('FAILED...', error);
      });
    },
  },

  mounted() {
    init("user_cdb4bXqJWqku704ktgdvK");
  },

  watch: {
    success() {
      setTimeout(() => {
        this.success = false;
      }, 3000)
    }
  }
}
</script>

<style lang="scss">
.input-error {
  color: red;
  font-size: 14px;
  padding-top: 5px;
}
</style>
