<template>
  <div class="map_wrap">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d362.324075193198!2d6.678690402052681!3d52.3447375910307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4eb3da475cfcb1%3A0x361f258807fc6e67!2sJeremy%20Stam!5e0!3m2!1snl!2snl!4v1632399377917!5m2!1snl!2snl"
        width="600" height="450" style="border:0;filter: invert(90%)" allowfullscreen="" loading="lazy"></iframe>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
