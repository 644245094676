<template>
  <div class="jeremy_tm_copyright">
    <div class="container">
      <div class="copyright_inner">
        <ul>
          <li v-scroll-reveal="{ delay: 20, distance: '20px' }">
            <span>Jeremy Stam &copy; {{ new Date().getFullYear() }}</span>

            <span>
              All rights reserved.</span>
          </li>
          <!-- End li -->

          <li v-scroll-reveal="{ delay: 40, distance: '20px' }">
            <span>Smaragdstraat 41,</span>
            <span>7601 HB, Almelo</span>
          </li>
          <!-- End li -->

          <li v-scroll-reveal="{ delay: 60, distance: '20px' }">
            <span><a href="#">hallo@jeremystam.nl</a></span>
            <span>+31 6 839 414 19</span>
          </li>
          <!-- End li -->

          <li v-scroll-reveal="{ delay: 80, distance: '20px' }">
            <span>kvk:  83765549</span>

            <div class="social">
              <ul>
                <li v-for="(social, i) in socialList" :key="i">
                  <a :href="social.link" target="_blank">
                                  <span class="first"
                                  ><img class="svg" :src="social.icon" alt="social icon"/></span>
                    <span class="second"
                    ><img class="svg" :src="social.icon" alt="social icon"
                    /></span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- End .copyright_inner -->
    </div>
    <!-- End .container -->
  </div>
  <!-- End .jeremy_tm_copyright -->
</template>

<script>
export default {
  data() {
    return {
      socialList: [
        {
          icon: require("../assets/img/svg/social/linkedin.svg"),
          link: "https://www.linkedin.com/in/jeremystam/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
