<template>
  <div>
    <Header />
    <!-- End Header -->

    <Slider />
    <!-- End Slider -->


    <About />
    <!-- End About -->

    <Service />
    <!-- End Service -->

    <div class="jeremy_tm_portoflio" id="portfolio">
      <Portfolio />
    </div>
    <!-- End Portfolio -->

    <Testimonial />
    <!-- End Testimonail -->


    <div class="jeremy_tm_contact" id="contact">
      <div class="bg_image"></div>
      <div class="container">
        <div class="contact_inner">
          <div class="jeremy_tm_main_title">
            <div
              class="title"
              v-scroll-reveal="{ delay: 20, distance: '20px' }"
            >
              <h3>Stuur een bericht<span class="bg">Contact</span></h3>
            </div>
          </div>
          <div class="desc" v-scroll-reveal="{ delay: 30, distance: '20px' }">
            <p>
              Gewoon een keer sparren over een idee of heb je een vraag?
              Stuur een bericht en ik reageer zo snel mogelijk. <span style='font-size:20px; font-style: normal'>&#128512;</span>
            </p>
          </div>
          <div class="wrapper">
            <div class="left" v-scroll-reveal="{ delay: 40 }">
              <Contact />
            </div>
            <div class="right" v-scroll-reveal="{ delay: 60 }">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End contact -->

    <Footer />

    <!-- End End Footer -->
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Slider from "../components/SliderThree.vue";
import About from "../components/About.vue";
import Service from "../components/Service.vue";
import Portfolio from "../components/Portfolio.vue";
import Testimonial from "../components/Testimonial.vue";
import Contact from "../components/Contact.vue";
import Map from "../components/Map.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    Slider,
    About,
    Service,
    Portfolio,
    Testimonial,
    Contact,
    Map,
    Footer,
  },
  mounted() {
    document.body.classList.add("dark");
  },
  destroyed() {
    document.body.classList.remove("dark");
  },
};
</script>

<style lang="scss" scoped></style>
