<template>
  <!-- SERVICES -->
  <div class="jeremy_tm_service">
    <div class="container">
      <div class="service_inner">
        <ul>
          <hooper :settings="hooperSettings">
            <slide v-for="(service, i) in serviceContent" :key="i">
              <div class="list_inner">
                <img class="svg" :src="service.imgSrc" alt="service image"/>
                <div class="details">
                  <h3>{{ service.title }}</h3>
                  <p>
                    {{ service.desc }}
                  </p>
                </div>
              </div>

              <!-- End item -->
            </slide>
          </hooper>
        </ul>
      </div>
    </div>
  </div>
  <!-- /SERVICES -->
</template>

<script>
import {Hooper, Slide} from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    Hooper,
    Slide,
  },
  data() {
    return {
      serviceContent: [
        {
          imgSrc: require("../assets/img/svg/code.svg"),
          title : "Web Development",
          desc  : ` Bouwen aan jou online zichtbaarheid door middel van een website of een dashboard waarin data
                  inzichtelijk wordt gemaakt. Door de juiste tools brengen we jouw op een voorsprong.`,
        },
        {
          imgSrc: require("../assets/img/svg/link.svg"),
          title : "API Development",
          desc  : ` Een API is voor verschillende doeleinden te gebruiken. Wil jij data toegankelijk maken of een API integregen in
                  je huidige software pakket? Samen zoeken we voor jou de juiste oplossing.`,
        },
        {
          imgSrc: require("../assets/img/svg/key.svg"),
          title : "Koppelingen & Automatiseren",
          desc  : ` Door externe systemen aan de huidige software van jouw bedrijf te koppelen en handelingen te automatiseren
                  voorkom je tijdrovende handelingen en bespaar je onnodige kosten. `,
        },
      ],
      hooperSettings: {
        infiniteScroll: true,
        wheelControl  : false,
        autoPlay      : true,
        mouseDrag     : true,
        transition    : 800,
        playSpeed     : 3500,
        breakpoints   : {
          1200: {
            itemsToShow: 2,
          },
          576 : {
            itemsToShow: 2,
          },
          280 : {
            itemsToShow: 1,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
.service_inner {
  .hooper-slide {
    padding-right: 30px;
  }
}
</style>
