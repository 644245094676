<template>
  <div class="jeremy_tm_testimonials" id="testimonial">
    <div class="container">
      <div class="jeremy_tm_main_title">
        <div class="title" v-scroll-reveal="{ delay: 20, distance: '20px' }">
          <h3>Feedback<span class="bg">Testimonial</span></h3>
        </div>
      </div>
      <!-- jeremy_tm-main_title -->

      <div class="testi_inner">
        <div class="left" v-scroll-reveal="{ delay: 40 }">
          <div class="quote_list">
            <ul>
              <li v-if="activetab === 1" class="tabcontent active">
                <img
                    class="svg"
                    src="../assets/img/svg/quote-1.svg"
                    alt=""
                />
                <p class="text">
                  Zeer tevreden over de kwaliteit wat geleverd word. Adequaat, meedenkend en oplossingsgericht. Topper en een aanrader voor
                  diegene die een snel en doelgerichte aanpak zoekt.
                </p>
                <div class="details">
                  <div class="image">
                    <div
                        class="main"
                        :style="{ backgroundImage: 'url(' + imgOne + ')' }"
                    ></div>
                  </div>
                  <div class="short">
                    <h3 class="author"><span>Leroy Drenthen</span></h3>
                    <h3 class="job"><span>Eigenaar IT-Centre</span></h3>
                  </div>
                </div>
              </li>
              <!-- End li -->

              <li v-if="activetab === 2" class="tabcontent active">
                <img
                    class="svg"
                    src="../assets/img/svg/quote-1.svg"
                    alt=""
                />
                <p class="text">
                  Jeremy is what I call a real life superhero. He has saved me and my business from total devastation on
                  more than one occasion. I am not sure what I would do without his expertise.
                </p>
                <div class="details">
                  <div class="image">
                    <div
                        class="main rounded"
                        :style="{ backgroundImage: 'url(' + imgTwo + ')'}"
                    ></div>
                  </div>
                  <div class="short">
                    <h3 class="author"><span>Ben Rea</span></h3>
                    <h3 class="job"><span>Senior Marketing Specialist | Analyst & Strategist | Content Creator</span>
                    </h3>
                  </div>
                </div>
              </li>
              <!-- End li -->

              <li v-if="activetab === 3" class="tabcontent active">
                <img
                    class="svg"
                    src="../assets/img/svg/quote-1.svg"
                    alt=""
                />
                <p class="text">
                  ...
                </p>
                <div class="details">
                  <div class="image">
                    <div
                        class="main"
                        :style="{ backgroundImage: 'url(' + imgThree + ')' }"
                    ></div>
                  </div>
                  <div class="short">
                    <h3 class="author"><span>Ken Sleebos</span></h3>
                    <h3 class="job"><span>Software & Data engineer</span></h3>
                  </div>
                </div>
              </li>
              <!-- End li -->
            </ul>
          </div>
        </div>
        <!-- End left -->

        <div class="right" v-scroll-reveal="{ delay: 80 }">
          <div class="image_list">
            <ul class="masonry">
              <li
                  class="masonry_item"
                  v-on:click="activetab = 1"
                  :class="[activetab === 1 ? 'active' : '']"
              >
                <div class="image">
                  <img src="../assets/img/thumbs/3-4.jpg" alt="thumb"/>
                  <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgOne + ')' }"
                  ></div>
                </div>
              </li>
              <!-- End image One -->
              <li
                  class="masonry_item"
                  v-on:click="activetab = 2"
                  :class="[activetab === 2 ? 'active' : '']"
              >
                <div class="image">
                  <img src="../assets/img/thumbs/4-3.jpg" alt="thumb"/>
                  <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgTwo + ')' }"
                  ></div>
                </div>
              </li>
              <!-- End image Two -->
              <li
                  class="masonry_item"
                  v-on:click="activetab = 3"
                  :class="[activetab === 3 ? 'active' : '']"
              >
                <div class="image">
                  <img src="../assets/img/thumbs/1-1.jpg" alt="thumb"/>
                  <div
                      class="main"
                      :style="{ backgroundImage: 'url(' + imgThree + ')' }"
                  ></div>
                </div>
              </li>
              <!-- End image Three -->
            </ul>
          </div>
        </div>
        <!-- End right -->
      </div>
      <!-- End testi_inner -->
    </div>
  </div>
  <!-- End Testimonials -->
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      imgOne: require("../assets/img/about/leroydrenthen.webp"),
      imgTwo: require("../assets/img/about/benrea.webp"),
      imgThree: require("../assets/img/about/ken-sleebos.jpeg"),
    };
  },
};
</script>

<style lang="scss" scoped>

.rounded {
  border-radius: 50%;
}
</style>
