<template>
  <div class="jeremy_tm_about" id="about">
    <div class="container">
      <div class="about_inner">
        <div class="left" v-scroll-reveal="{ delay: 40 }">
          <img
              style="filter: brightness(75%)"
              class="thumbnail"
              src="../assets/img/about/jeremy_profiel.png"
              alt="man with sunglass"
          />
        </div>
        <!-- End left thumbnail -->
        <div class="right">
          <div class="name" v-scroll-reveal="{ delay: 40 }">
            <h3>Jeremy Stam<span class="bg">About</span></h3>
            <span class="job">Software Developer</span>
          </div>
          <div class="text">
            <p v-scroll-reveal="{ delay: 60 }">
              Met mijn passie voor webdevelopment help ik
              mensen met het maken van de volgende stap richting de toekomst. Van een strakke website tot het
              automatiseren van saaie en tijdrovende processen
            </p>
            <p v-scroll-reveal="{ delay: 80 }">
              Als software ontwikkelaar help ik jou graag met het creeëren van kansen en het bouwen van nieuwe
              oplossingen.
            </p>
          </div>
          <div class="jeremy_tm_button" v-scroll-reveal="{ delay: 100 }">
<!--            <button class="ib-button" @click="showModal">-->
<!--              <span>Lees meer</span>-->
<!--            </button>-->
          </div>
        </div>
        <!-- End right content -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
          :class="{ 'modal-mask': isActive }"
          id="modal"
          @click="closeModal"
          v-if="isVisible"
      >
        <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
            @click.stop
        >
          <div class="modal-content">
            <div class="jeremy_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                    class="svg"
                    src="../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Development Skills</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="jeremy_progress">
                    <div class="progress_inner">
                      <span
                      ><span class="label">Laravel &amp; PHP</span
                      ><span class="number">95%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                      ><span class="label">Javascript &amp; VueJS</span
                      ><span class="number">80%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:80%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                      ><span class="label">HTML &amp; CSS</span
                      ><span class="number">90%</span
                      ></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:90%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                  </div>
                  <!-- End .jeremy_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3>Language Skills</h3>
                  </div>
                  <div class="jeremy_progress">
                    <div class="progress_inner">
                      <span
                      ><span class="label">Nederlands</span
                      ><span class="number">95%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                      ><span class="label">Engels</span
                      ><span class="number">90%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:90%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                    </div>
                  </div>
                </div>
                <!-- End .right -->
              </div>
              <!--              <div class="counter">-->
              <!--                <div class="about_title">-->
              <!--                  <h3>Fun Facts</h3>-->
              <!--                </div>-->
              <!--                <ul>-->
              <!--                  <li>-->
              <!--                    <div class="list_inner">-->
              <!--                      <h3>777+</h3>-->
              <!--                      <span>Projects Completed</span>-->
              <!--                    </div>-->
              <!--                  </li>-->
              <!--                  <li>-->
              <!--                    <div class="list_inner">-->
              <!--                      <h3>3K</h3>-->
              <!--                      <span>Happy Clients</span>-->
              <!--                    </div>-->
              <!--                  </li>-->
              <!--                  <li>-->
              <!--                    <div class="list_inner">-->
              <!--                      <h3>9K+</h3>-->
              <!--                      <span>Lines of Code</span>-->
              <!--                    </div>-->
              <!--                  </li>-->
              <!--                </ul>-->
              <!--                &lt;!&ndash; End ul &ndash;&gt;-->
              <!--              </div>-->
              <!-- End .counter -->
              <div class="partners">
                <div class="about_title">
                  <h3>Our Partners</h3>
                </div>
                <Brand/>
              </div>
              <!-- End .partner -->
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
  <!-- End about -->
</template>

<script>
import Brand from "./Brand.vue";

export default {
  components: {
    Brand,
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function () {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function () {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>
